<template>
  <div class="header-components">
    <span class="icon-span"><img class="logo" src="../assets/img/logo.png"/></span>
    <ul>
      <li :id="l.path" class="link-li" v-for="l in linkList" :key="l.path" @click="jumpTo(l,false)">
        <a href="#">{{ l.label }}</a>
        <img :style="subLineShow(l)" class="subline" src="../assets/img/nav-subline.png"/>
      </li>
    </ul>
    <div class="sub-nav" v-if="!!popObject && !!moreNavShow" id="subNav">
        <div class="margin-both" v-for="k in Object.keys(popObject.pop)" :key="k">
          <h3>{{ k }}</h3>
          <ul class="header-nav">
            <li v-for="i in popObject.pop[k]" :key="i.key">
              <div :class="solutionType == i.key ? 'border-select' : ''">
                <span class="sign"></span>
                <div class="content-box"  @click="jumpToSubTitle(popObject, i)">
                  <p class="title">{{ i.title  }}</p>
                  <p class="desc">{{  i.desc }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Header',
  computed: {
    currentRouterPath() {
      return this.$store.state.currentRouterPath
    },
    solutionType() {
      return this.$store.state.solutionType
    },
    
  },
  data() {
    return {
      popObject: "",
      moreNavShow: '',
      linkList: [
        {
          path: 'home',
          label: '首页',
          pop: ''
        },
        {
          path: 'genHuman',
          label: 'GenHuman',
          pop: '',
        },
        {
          path: 'solution',
          label: '解决方案',
          pop: {
            '功能场景': [
              {
                key: 'human',
                title: '中之人驱动虚拟人',
                desc: '真人驱动的虚拟人，常用于综艺节目、发布会、娱乐直播等'
              },
              {
                key: 'intellect',
                title: 'AI 智能虚拟人',
                desc: '为用户提供接近真人的互动、咨询、讲解、客服导览等服务',
              },
              {
                key: 'broadcast',
                title: 'AI 播报虚拟人',
                desc: '文本快速生成逼真视频，常用于短视频制作、新闻播报等',
              },
              {
                key: 'digital',
                title: '数字分身虚拟人',
                desc: '1V1复刻真人，常用于短视频制作、新闻播报等；',
              },
              {
                key: 'virtual',
                title: '虚拟人内容创作',
                desc: '主要内容类型为各类传统的长中短虚拟内容如:影视动画、游戏、 虚拟综艺、MV、短视频图片等',
              },
              
              {
                key: 'meta',
                title: '元宇宙',
                desc: '元宇宙空间服务集合，打通一体化服务',
              }
            ]
          }
        },
        // {
        //   path: 'GenMarket',
        //   label: 'GenMarket',
        //   pop: ''
        // },
        {
          path: 'geniverse',
          label: 'Geniverse',
          pop: ''
        },
        {
          path: 'aboutAsyou',
          label: '关于爱希游',
          pop: {
            '关于爱希游': [
              {
                key: 'aboutAsyou',
                title: '公司简介',
                desc: '',
              },
              {
                key: 'mediaCenter',
                title: '媒体中心',
                desc: '',
              },
              {
                key: 'join',
                title: '加入我们',
                desc: '',
              }
            ]
          }
        },
        {
          path: 'contact',
          label: '联系我们',
          pop: ''
        }
      ]
    }
  },
  methods: {
    subLineShow(l) {
      if (this.moreNavShow == l.path) {
        return {opacity: 1}
      } else if (this.moreNavShow == '' && this.currentRouterPath.indexOf(l.path) > -1) {
        return {opacity: 1}
      } else {
        return {}
      }
    },
    jumpTo(l, showNav) {
      this.moreNavShow = l.path;
      if(!!l.pop) {
        this.popObject = l;
        this.$nextTick(() => {
          const subNav = window.document.querySelector(".sub-nav");
          subNav.style.display = 'block';

          document.getElementById("subNav").addEventListener("mouseleave", ()=>{
          this.$nextTick(() => {
            const subNav = window.document.querySelector(".sub-nav");
						if (!!subNav)  subNav.style.display = 'none';
          })
          this.moreNavShow = '';
        })
        })
        
         return
      }else {
        this.$nextTick(() => {
          const subNav = window.document.querySelector(".sub-nav");
					if (!!subNav) subNav.style.display = 'none';
        })
        this.moreNavShow = '';
      }
			if (!showNav) {
				this.$router.push({
				  path: '/asyou/'+l.path
				})
			}
     
    },
    jumpToSubTitle(l, i) {
      this.$store.state.solutionType = i.key;
      if ('/asyou/solution' == this.$store.state.currentRouterPath) return;
      this.$nextTick(() => {
          const subNav = window.document.querySelector(".sub-nav");
					if (!!subNav) subNav.style.display = 'none';
          
        }) 
      if (l.path == 'solution') {
        this.$router.push({
          path: '/asyou/'+l.path,
          query: {
            type: i.key
          }
        })
      } 
      if (l.path == 'aboutAsyou') {
        this.$router.push({
          path: '/asyou' + '/' + i.key,          
        })
      }
    },
  },
  mounted() {
       this.$nextTick(() => {
				 let linkLi = document.querySelectorAll('.link-li');
				 for (let i = 0; i < linkLi.length; i++) {
					 // console.log(linkLi[i]);
						linkLi[i].addEventListener("mouseenter", () => {
							// console.log(this.linkList);
							this.jumpTo(this.linkList[i], true);
						})
				 }
			 })
  }

}
</script>
<style lang="scss" scoped>

@media screen and (max-width:1200px) {

}

  .header-components {
    position: fixed;
    left: 0;
    right: 0;
    top: 0px;
    height: 1.1rem;
    background: rgba($color: #000000, $alpha: 1);
    font-size: 0.2rem;
    font-family: MicrosoftYaHei;
    color: #DCDCDC;
    display: flex;
    align-items: center;
    z-index: 1000;
    span.icon-span {
      z-index:1001;
      display: inline-blsock;
      width: 14%;
      .logo {
        margin-left: 0.59rem;
        height: 0.5rem; 
      }
     }
   
    ul {
      z-index:1002;
      padding-top: 0.3rem;
      height: 100%;
      flex: 1;
      display: flex;
      align-items: flex-end;
        li {
          width: 16%;
          height: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          position: relative;
        
          a.router-link-exact-active {
            color: white;
          }
          a:hover {
            color: rgba(255,255,255,0.6)
          }
          .subline {
            opacity: 0;
            margin-top: 0.15rem;
            height: 0.25rem;
          }
          &:hover .subline {
            opacity: 1;
          }
      }
    }
  }
  .header-components .sub-nav {
    position: absolute;
    background: linear-gradient(180deg, rgba(0,0,0,0.18) 0%, rgba(10,57,132,0) 100%, rgba(0,0,0,0) 100%);
    backdrop-filter: blur(24px);
    left: 0;
    top: 0;
    padding-top: 1.1rem;
    padding-bottom: 0.5rem;
    width: 100%;
    z-index: 800;
    transition: all 0.3s ease-in-out;

    div {      
      h3 {
        margin-top: 0.7rem;
        font-size: 0.2rem;
        color: rgba(255,255,255,0.6);
        padding-bottom: 0.2rem;
        border-bottom: 0.01rem solid rgba(84,212,247,0.1);
      }
    ul.header-nav {
      display: flex;
      flex-wrap: wrap;

      li {
        width: 33%;
        margin-top: 0.32rem;
        cursor: pointer;
        border:0.01rem solid transparent;

        > div {
          width: 100%;
          padding: 0.2rem 0.26rem;
          display: flex;
          .sign {
            display: inline-block;
            width: 0.16rem;
            height: 0.22rem;
            background: url(../assets/img/sign.svg) no-repeat; 
            background-size: 100% 100%;
          }

          .content-box {
            flex: 1;
            margin-left: 0.16rem;
            padding-right: 0.22rem;
            
            .title {
              color: white;
              font-size: 0.16rem;
            }
            .desc {
              margin-top: 0.08rem;
              color: rgba($color: #fff, $alpha: 0.5);
              font-size: 0.12rem;
            }

        }
        }
      }
      li:hover {
          box-sizing: border-box;
          border: 0.01rem solid rgba(84,212,247,0.1);
      }
    }
    }
  }
</style>
