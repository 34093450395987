<template>
    <span class="section-title">
         {{ title }}
    </span>
</template>
<script>

export default {
    name: 'SectionTitle',
    props: ['title'],
    data() {
        return {

        }
    }
}
</script>
<style lang="scss" scoped>
    .section-title {
        width: 2.37rem;
        display: inline-block;
        text-align: center;
        padding-top: 0.18rem;
        padding-bottom: 0.18rem;
        font-size: 0.28rem;
        background: url("../assets/img/section-title.png") no-repeat;
        background-size: 100% 100%;
    }
</style>