import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const CURRENTROUTER = 'CURRENTROUTER'
const SOLUTIONTYPE = 'SOLUTIONTYPE'

export default new Vuex.Store({
  state: {
    currentRouterPath: '',
    solutionType: ''
  },
  mutations: {
    [CURRENTROUTER](state, value) {
      state.currentRouterPath = value
    },
    [SOLUTIONTYPE](state, value) {
      state.solutionType = value
    },
  },
  actions: {
  },
  modules: {
  }
})
