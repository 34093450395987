import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from "element-ui";
import "@/components/components.js";
import "@/assets/scss/index.scss";
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Element);

import "/static/fullpage.css";

import "/static/page.js";
import "/static/rem.js";



Vue.config.productionTip = false
Vue.prototype.$store = store;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
