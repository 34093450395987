<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import HeaderNav from "@/components/header";
export default {
  name: 'App',
  components: {
    HeaderNav
  },
  mounted(){
    // console.log("nou");
    // console.log("dd");
    // window.onresize=function(){
    //   refreshScale()
    // }
  },
  watch: {
    $route(to, from) {
			this.$store.state.currentRouterPath = to.fullPath;
      if (to.fullPath.includes('/asyou/solution')) {
        this.$store.state.solutionType = to.query.type;
      } 
      
		},
  }
}
</script>
<style>
#app {
  font-family: MicrosoftYaHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  color: white;
  width: 100%;
  height: 100%;

}
a.router-link-exact-active {
  color: #42b983;
}
</style>
