import Vue from 'vue'
import VueRouter from 'vue-router'
// const Layout = ;
Vue.use(VueRouter)

// 解决vue-router在3.0版本以上重复报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    redirect: '/asyou'
  },
  {
    path: "/asyou",
    component: () => import("@/views/Layout/index.vue"),
    // hidden: false,
    redirect: "/asyou/home",
    children: [
      {
        name: 'home',
        path: 'home',
        meta: {
          title: '首页',
          hidden: false
        },
        component: () => import("@/views/home/index.vue")
      },
      {
        name: 'genHuman',
        path: 'genHuman',
        meta: {
          title: 'GenHuman',
          hidden: false
        },
        component: () => import("@/views/genHuman/index.vue")
      },
      {
        name: 'solution',
        path: 'solution',
        meta: {
          title: '解决方案',
          hidden: false
        },
        component: () => import("@/views/solution/index.vue")
      },
      // {
        
      //   name: 'GenMarket',
      //   path: 'GenMarket',
      //   meta: {
      //     title: 'GenMarket',
      //     hidden: false
      //   },
      //   component: () => import("@/views/GenMarket/index.vue")
      // },
      {
        name: 'geniverse',
        path: 'geniverse',
        meta: {
          title: 'geniverse',
          hidden: false
        },
        component: () => import("@/views/geniverse/index.vue")
      },
      {
        name: 'contact',
        path: 'contact',
        meta: {
          title: '联系我们',
          hidden: false
        },
        component: () => import("@/views/aboutAsyou/contact.vue")
      },
      {
        name: 'aboutAsyou',
        path: 'aboutAsyou',
        component: () => import("@/views/aboutAsyou/index.vue"),
        meta: {
          title: '公司简介',
          hidden: false
        },
      },
      {
        name: 'mediaCenter',
        path: 'mediaCenter',
        component: () => import("@/views/aboutAsyou/mediaCenter.vue")
      },
      {
        name: 'mediaDetail',
        path: 'mediaDetail',
        component: () => import("@/views/aboutAsyou/mediaDetail.vue")
      },
      {
        name: 'join',
        path: 'join',
        component: () => import("@/views/aboutAsyou/join.vue")
      },
      {
        name: 'jobDetail',
        path: 'jobDetail',
        component: () => import("@/views/aboutAsyou/jobDetail.vue")
      },
      // {
      //   name: 'aboutAsyou',
      //   path: 'aboutAsyou',
      //   // component: () => import("@/views/Layout/index.vue"),
      //   redirect: "/asyou/aboutAsyou/index",
      //   meta: {
      //     title: '关于爱希游',
      //     hidden: false
      //   },
      //   children: [
      //     {
      //       name: 'index',
      //       path: 'index',
      //       component: () => import("@/views/aboutAsyou/index.vue"),
      //       meta: {
      //         title: '公司简介',
      //         hidden: false
      //       },
      //     },
      //     {
      //       name: 'mediaCenter',
      //       path: 'mediaCenter',
      //       component: () => import("@/views/aboutAsyou/mediaCenter.vue")
      //     },
      //     {
      //       name: 'join',
      //       path: 'join',
      //       component: () => import("@/views/aboutAsyou/join.vue")
      //     },
          
      //     // {
      //     //   name: 'solution',
      //     //   path: 'solution',
      //     //   component: () => import("@/views/solution/index.vue")
      //     // }
      //   ]
        

      // }
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
